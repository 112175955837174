// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-jsx": () => import("./../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-collection-jsx": () => import("./../src/templates/collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-video-jsx": () => import("./../src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-application-jsx": () => import("./../src/templates/application.jsx" /* webpackChunkName: "component---src-templates-application-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-js": () => import("./../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-applications-js": () => import("./../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-carry-us-js": () => import("./../src/pages/carry-us.js" /* webpackChunkName: "component---src-pages-carry-us-js" */),
  "component---src-pages-collection-all-js": () => import("./../src/pages/collection/all.js" /* webpackChunkName: "component---src-pages-collection-all-js" */),
  "component---src-pages-color-list-js": () => import("./../src/pages/color-list.js" /* webpackChunkName: "component---src-pages-color-list-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-education-js": () => import("./../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kenra-color-js": () => import("./../src/pages/kenra-color.js" /* webpackChunkName: "component---src-pages-kenra-color-js" */),
  "component---src-pages-kenra-platinum-js": () => import("./../src/pages/kenra-platinum.js" /* webpackChunkName: "component---src-pages-kenra-platinum-js" */),
  "component---src-pages-kenra-js": () => import("./../src/pages/kenra.js" /* webpackChunkName: "component---src-pages-kenra-js" */),
  "component---src-pages-loyalty-js": () => import("./../src/pages/loyalty.js" /* webpackChunkName: "component---src-pages-loyalty-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-quiz-js": () => import("./../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-salon-locator-js": () => import("./../src/pages/salon-locator.js" /* webpackChunkName: "component---src-pages-salon-locator-js" */),
  "component---src-pages-submitted-js": () => import("./../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-tools-js": () => import("./../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-video-js": () => import("./../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

