import React from 'react';
import { GlobalStyle } from '@matchbox-mobile/kenra-storybook';

class Layout extends React.Component {
  render() {
    return (
      <>
        <GlobalStyle />
        {this.props.children}
      </>
    );
  }
}

export default Layout;
